import { OutreachEditorType } from '@recruitrobin/robin-theme/web-components';
import { useQueryClient } from '@tanstack/react-query';
import { useIsWerknlActive } from 'hooks/search/useIsWerknlActive';
import { useLocalStorage } from 'hooks/shared';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CandidateContext } from 'shared/contexts/CandidateContext/CandidateContext';
import { useContextSelector } from 'use-context-selector';
import { DISMISS_SHOULD_CALL_WERKNL_MODAL } from '../ShouldCallWerknlOutreachDialog/ShouldCallWerknlOutreachDialog';

const useCallWerknlModal = () => {
  const isWerknlActive = useIsWerknlActive();
  const isWerknlActiveRef = useRef(isWerknlActive);
  const queryClient = useQueryClient();
  const setShouldShowOutreachModal = useContextSelector(CandidateContext, (s) => s.setShouldShowOutreachModal);
  const { value: dismissCallWerknlModal } = useLocalStorage<boolean>(DISMISS_SHOULD_CALL_WERKNL_MODAL, false);

  useEffect(() => {
    isWerknlActiveRef.current = isWerknlActive;
  }, [isWerknlActive]);

  const [isVisible, setIsVisible] = useState(false);

  const callWerknlModal = useCallback(() => {
    if (!isWerknlActiveRef.current) {
      if (dismissCallWerknlModal) {
        return setIsVisible(false);
      } else {
        return setIsVisible(true);
      }
    }

    setShouldShowOutreachModal(OutreachEditorType.Werknl);
  }, [dismissCallWerknlModal, setShouldShowOutreachModal]);

  const onConfirm = () => {
    setIsVisible(false);
    queryClient.resetQueries(['crawler-is-werknl-active']);
  };

  return { isWerknlActive, isVisible, onConfirm, callWerknlModal };
};

export default useCallWerknlModal;

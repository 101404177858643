import { Candidate } from 'model';
import { useContext, useMemo } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { FiltersContext } from 'shared/contexts/SearchContext';
import { SelectedCampaignContext } from 'shared/contexts/SelectedCampaignContext';
import { CandidateStatus } from 'types/candidate';
import { useContextSelector } from 'use-context-selector';
import { extractExperiences } from './extractExperiences';
import { extractLastRefresh } from './extractLastRefresh';
import { extractLocation } from './extractLocation';
import { extractPreferences } from './extractPreferences';
import {
  CandidateDetailsTab,
  SearchV3CandidateResponse,
  SearchV3CandidateStatefulResponse,
  SearchV3CandidateUIState,
  Searchv3CandidateHandler,
} from './types';
import { extractEducation } from './extractEducation';

export const useSearchV3Candidate: Searchv3CandidateHandler = (_candidate, { config = initialState.config }) => {
  const { dictionary, language } = useContext(LocalizationContext);
  const filterLocations = useContextSelector(FiltersContext, (state) => state.filters.locations);
  const selectedCandidate = useContextSelector(SelectedCampaignContext, (state) => state.selectedCandidate);
  const setSelectedCandidate = useContextSelector(SelectedCampaignContext, (state) => state.setSelectedCandidate);
  const setSelectedCandidateConfig = useContextSelector(
    SelectedCampaignContext,
    (state) => state.setSelectedCandidateConfig,
  );

  const isSelected =
    _candidate?.key === selectedCandidate?.key && _candidate !== undefined && selectedCandidate !== undefined;

  const candidate = useMemo<SearchV3CandidateResponse>(() => {
    if (!_candidate) return initialState.candidate;

    const { name, es_person_id } = _candidate;

    const location = extractLocation(_candidate, filterLocations);

    const lastRefresh = extractLastRefresh(_candidate.lastRefresh, language);

    const experiences = extractExperiences(_candidate.experiences, dictionary);

    const preferences = extractPreferences(_candidate.preferences, dictionary);

    const education = extractEducation(_candidate.education, dictionary);

    return {
      key: _candidate.key,
      name,
      es_person_id,
      location,
      isSelected,
      lastRefresh,
      experiences,
      preferences,
      education,
      history,

      select: (_c) => {
        const c = initialState.config;

        setSelectedCandidateConfig({
          ...c,
          candidateDetails: { ...c.candidateDetails, ...(_c?.candidateDetails ?? {}) },
        });
        setSelectedCandidate(_candidate);
      },
      bindMatchesAndGetJobboards: (_matches) => {
        const matches = _matches[_candidate.es_person_id];

        if (_candidate.platformMatches.length === 0) {
          _candidate.bindCandidateMatches(matches);
        }

        return _candidate.jobBoards;
      },
    };
  }, [_candidate, filterLocations, isSelected, dictionary]);

  const candidateUIState = useMemo<SearchV3CandidateUIState>(() => {
    if (!_candidate) return initialState.candidateUIState;

    const status = getCandidateStatus(_candidate.status);

    return {
      isLoading: false,
      isSelected: _candidate.key === selectedCandidate?.key,
      status,
    };
  }, [_candidate, selectedCandidate?.key]);

  return useMemo(
    () => ({ config, candidate, candidateUIState }),
    [config.candidateDetails.initialTab, candidate, candidateUIState],
  );
};

export const initialState: SearchV3CandidateStatefulResponse = {
  config: { candidateDetails: { initialTab: CandidateDetailsTab.CV } },

  candidateUIState: {
    isLoading: true,
    isSelected: false,
    status: CandidateStatus.New,
  },

  candidate: {
    key: '',
    name: '',
    es_person_id: '',
    experiences: [],
    preferences: [],
    education: [],
    select: () => {
      throw Error('not implemented');
    },
    bindMatchesAndGetJobboards: () => {
      throw Error('not implemented');
    },
  },
};

const getCandidateStatus = (status: Candidate['status']): CandidateStatus => {
  if (status === 'CONTACTED') return CandidateStatus.Contacted;
  if (status === 'HIDDEN') return CandidateStatus.Hidden;
  if (status === 'INVISIBLE') return undefined as any;
  if (status === 'MATCHED') return CandidateStatus.Matched;
  if (status === 'NEW') return CandidateStatus.New;
  if (status === 'SHORTLISTED') return CandidateStatus.Shortlisted;

  return CandidateStatus.Hidden;
};

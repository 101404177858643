import type { CandidateOutreachModalContextState } from './CandidateOutreachModalContext.types';

import { createContext } from 'use-context-selector';
import { notImplemented } from 'utils/notImplemented';

export const initialState: CandidateOutreachModalContextState = {
  candidate: null,
  type: undefined,
  candidateOutreachModalRef: null,
  updateCandidate: notImplemented,
  setCandidateOutreachModal: notImplemented,
};

export const CandidateOutreachModalContext = createContext(initialState);

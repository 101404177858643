import { Indeed, Linkedin, LinkedinFill, Mail_01, Phone, Werknl, Whatsapp } from '@recruitrobin/robin-theme/web-icons';
import { ThemeColorCallback } from '@recruitrobin/robin-theme/web-styles';
import { Switch } from 'components/ui/atoms';
import { useIsWerknlActive } from 'hooks/search/useIsWerknlActive';
import { ReactNode, useContext, useMemo } from 'react';
import { CandidateContext } from 'shared/contexts/CandidateContext/CandidateContext';
import { OutreachMethods } from 'shared/contexts/CandidateContext/types';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useBrowserExtension } from 'shared/hooks';
import { useContextSelector } from 'use-context-selector';

const useOutreachSettings = () => {
  const { dictionary } = useContext(LocalizationContext);
  const { indeedStatus, linkedinStatus, linkedinProStatus } = useBrowserExtension();
  const isWerknlActive = useIsWerknlActive();

  const outreachInformationWhyNotEnabled = useContextSelector(
    CandidateContext,
    (state) => state.outreachInformationWhyNotEnabled,
  );

  const isLinkedinLoggedIn = linkedinStatus === 'Logged In';
  const isLinkedinInMailLoggedIn = linkedinProStatus === 'Logged In';
  const isIndeedLoggedIn = indeedStatus === 'Logged In';

  const outreachMethodsSettings: Record<
    keyof OutreachMethods,
    {
      label: string;
      startIcon: (color?: ThemeColorCallback) => ReactNode;
      endIcon?: ReactNode;
      active: boolean;
      whyNotEnabled?: string;
    }
  > = useMemo(
    () => ({
      shouldEnableCall: {
        label: dictionary.call,
        startIcon: (color?: ThemeColorCallback) => <Phone size={16} color={color} />,
        active: true,
      },
      shouldEnableEmail: {
        label: dictionary.email,
        startIcon: (color?: ThemeColorCallback) => <Mail_01 size={16} color={color} />,
        active: true,
      },
      shouldEnableIndeed: {
        label: dictionary.indeed,
        startIcon: (color?: ThemeColorCallback) => <Indeed size={16} color={color} />,
        endIcon: !isIndeedLoggedIn && <Switch checked={false} variant="smallest" />,
        active: isIndeedLoggedIn,
        whyNotEnabled: outreachInformationWhyNotEnabled.whyIndeedNotEnabled?.label,
      },
      shouldEnableWhatsapp: {
        label: dictionary.whatsApp,
        startIcon: (color?: ThemeColorCallback) => <Whatsapp size={16} color={color} />,
        active: true,
      },
      shouldEnableLinkedin: {
        label: dictionary.linkedIn,
        startIcon: (color?: ThemeColorCallback) => <Linkedin size={16} color={color} />,
        endIcon: !isLinkedinLoggedIn && <Switch checked={false} variant="smallest" />,
        active: isLinkedinLoggedIn,
        whyNotEnabled: outreachInformationWhyNotEnabled.whyLinkedinNotEnabled?.label,
      },
      shouldEnableLinkedinPro: {
        label: dictionary.linkedInInMail,
        startIcon: (color?: ThemeColorCallback) => <LinkedinFill size={16} color={color} />,
        endIcon: !isLinkedinInMailLoggedIn && <Switch checked={false} variant="smallest" />,
        active: isLinkedinInMailLoggedIn,
        whyNotEnabled: outreachInformationWhyNotEnabled.whyLinkedinProNotEnabled?.label,
      },
      shouldEnableWerknl: {
        label: dictionary.Werknl,
        startIcon: (color?: ThemeColorCallback) => <Werknl size={16} color={color} />,
        endIcon: !isWerknlActive && <Switch checked={false} variant="smallest" />,
        active: isWerknlActive,
        whyNotEnabled: outreachInformationWhyNotEnabled.whyWerknlNotEnabled?.label,
      },
    }),
    [outreachInformationWhyNotEnabled, dictionary],
  );

  return {
    outreachMethodsSettings,
  };
};

export default useOutreachSettings;

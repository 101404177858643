import { Availability, EmploymentType, Preferences } from 'clients/CampaignsClient/CandidateResponse';
import { dictionary } from 'shared/contexts/LocalizationContext/languages/en';
import { Dictionary } from 'shared/contexts/LocalizationContext/types';

export type ShowingPreferences =
  | 'roles'
  | 'workingHours'
  | 'salary'
  | 'commuteDistance'
  | 'employmentType'
  | 'relocation'
  | 'availability'
  | 'locations';

const employmentTypeMapping: Record<EmploymentType, string> = {
  [EmploymentType.FULL_TIME]: dictionary.employmentTypeFullTime,
  [EmploymentType.PART_TIME]: dictionary.employmentTypePartTime,
  [EmploymentType.FREELANCE]: dictionary.employmentTypeFreelance,
  [EmploymentType.INTERNSHIP]: dictionary.employmentTypeInternship,
  [EmploymentType.SEASONAL]: dictionary.employmentTypeSeasonal,
  [EmploymentType.SHORT_TERM]: dictionary.employmentTypeShortTerm,
};

export const getPreferences = (
  _preferences: Preferences,
): Array<{
  title: keyof Pick<Dictionary, ShowingPreferences>;
  description: string;
}> => {
  const jobTitles = _preferences?.jobTitles ?? [];
  const minWorkingHours = _preferences?.workHours?.min ?? undefined;
  const maxWorkingHours = _preferences?.workHours?.max ?? undefined;
  const minSalary = _preferences?.salaryRange?.min ?? undefined;
  const maxSalary = _preferences?.salaryRange?.max ?? undefined;
  const salaryCurrency = _preferences?.salaryRange?.currency ?? undefined;
  const commuteDistance = _preferences?.commuteDistance?.km ?? undefined;
  const employmentType = _preferences.employmentType ?? undefined;
  const relocation = _preferences.relocation ?? undefined;
  const availability = _preferences.availability ?? undefined;
  const locations = (_preferences.locations?.length ?? 0) > 0 ? _preferences.locations : undefined;

  const preferences: Array<{
    title: keyof Pick<Dictionary, ShowingPreferences>;
    description: string;
  }> = [];

  if (jobTitles.length > 0) {
    preferences.push({
      title: 'roles',
      description: jobTitles.join(', '),
    });
  }

  if (minWorkingHours && maxWorkingHours) {
    preferences.push({
      title: 'workingHours',
      description: `${minWorkingHours}-${maxWorkingHours}`,
    });
  }

  if (minSalary && maxSalary) {
    preferences.push({
      title: 'salary',
      description: `${minSalary}-${maxSalary} ${salaryCurrency}`,
    });
  }

  if (commuteDistance) {
    preferences.push({
      title: 'commuteDistance',
      description: `${commuteDistance} km`,
    });
  }

  if (employmentType) {
    if (Array.isArray(employmentType)) {
      preferences.push({
        title: 'employmentType',
        description: employmentType.map((key) => employmentTypeMapping[key]).join(', '),
      });
    } else {
      preferences.push({
        title: 'employmentType',
        description: employmentTypeMapping[employmentType],
      });
    }

    if (relocation !== undefined) {
      preferences.push({
        title: 'relocation',
        description: relocation ? dictionary.yes : dictionary.no,
      });
    }
  }

  if (availability !== undefined) {
    preferences.push({
      title: 'availability',
      description: getAvailabilityText(availability),
    });
  }

  if (locations !== undefined) {
    preferences.push({
      title: 'locations',
      description: locations.join(', '),
    });
  }

  return preferences;
};

const getAvailabilityText = (availability: Date | string): string => {
  if (availability === Availability.IMMEDIATE) {
    return availability;
  }

  return new Intl.DateTimeFormat('nl-NL').format(new Date(availability));
};

/* eslint-disable @typescript-eslint/no-unused-vars*/
import { forwardRef } from 'react';
import { styleUtils } from 'utils';
import { useStyles } from './styles';
import { default as Checkbox } from 'components/ui/atoms/Checkbox';
import { default as Row } from 'components/ui/atoms/Row';
import { default as Typography } from 'components/ui/atoms/Typography';

import type { MenuItemProps } from './types';
import { Tooltip } from 'shared/components/Tooltip';

const MenuItem = forwardRef(
  (
    {
      icon,
      children,
      onClick,
      menuItemId,
      variant = 'default',
      disabled = false,
      withDivisor = false,
      disableMouseEvents = false,
      selected = false,
      prefixVariant = 'icon',
      highlight = false,
      tooltip,
    }: MenuItemProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const isCustomHandler = typeof children === 'function';
    const classes = useStyles({ disabled, withDivisor, disableMouseEvents, highlight, variant });

    const handleClick: React.MouseEventHandler = (e) => {
      if (disabled) return;

      e.preventDefault();
      e.stopPropagation();

      onClick();
    };

    return (
      <Tooltip title={tooltip?.label || ''} disabled={!tooltip?.label}>
        <span>
          <Row
            ref={ref}
            css={[classes.root, selected && prefixVariant === 'icon' && classes.selected]}
            gap={9}
            onClick={!isCustomHandler && !disableMouseEvents ? handleClick : undefined}
            tabIndex={disabled ? -1 : 0}
            alignCenter
            inline
            contained
            data-menu-item-id={menuItemId}>
            {prefixVariant && (prefixVariant === 'checkbox' || (prefixVariant === 'icon' && !!icon)) && (
              <Row css={classes.prefix} inline>
                {prefixVariant === 'icon' && icon}
                {prefixVariant === 'checkbox' && <Checkbox checked={selected} />}
              </Row>
            )}
            <Typography
              css={classes.menuItemTypography}
              className="rr-menu-item-typography"
              variant="supporting.helper"
              color="currentColor"
              nowrap
              contained>
              {!isCustomHandler ? children : children({ onClick })}
            </Typography>
          </Row>
        </span>
      </Tooltip>
    );
  },
);

export default MenuItem;

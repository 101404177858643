import { ReactNode, useContext, useMemo } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { CandidateSecondaryAction } from 'services/candidates/types';
import { ContextMenuOption } from 'components/ui/molecules/ContextMenu';
import { RefreshCcw_02, UserCheck_01, Bookmark, Link_03 } from '@recruitrobin/robin-theme/web-icons';

export const useSecondaryActionsToMenuOptions = (
  secondaryActions: CandidateSecondaryAction[],
): Array<ContextMenuOption & { key: CandidateSecondaryAction }> => {
  const { dictionary } = useContext(LocalizationContext);
  const label: Record<CandidateSecondaryAction, string> = {
    'RETRY-TO-SEND-TO-ATS': dictionary.tryResendAts,
    'SHORTLIST-TO-ANOTHER-SEARCH': dictionary.shortlistCandidateToOtherSearch,
    MATCH: dictionary.match,
    'SHARE-PROFILE': dictionary.copyLinkCandidateProfile,
  };

  const icons: Record<CandidateSecondaryAction, ReactNode> = {
    'RETRY-TO-SEND-TO-ATS': <RefreshCcw_02 size={16} />,
    'SHORTLIST-TO-ANOTHER-SEARCH': <Bookmark size={16} />,
    MATCH: <UserCheck_01 size={16} />,
    'SHARE-PROFILE': <Link_03 size={16} />,
  };

  return useMemo(() => {
    return secondaryActions.map((key) => ({
      key,
      label: label[key],
      icon: icons[key],
    }));
  }, [secondaryActions]);
};

import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      display: 'flex',
      flexDirection: 'column',
    }),
  actions: () =>
    css({
      marginTop: '15px',
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);

import { IconButton, Loader } from '@recruitrobin/robin-theme/web-components';
import { XClose } from '@recruitrobin/robin-theme/web-icons';
import { Row } from 'components/ui/atoms';
import { Link } from 'components/ui/atoms/icons';
import { default as Button } from 'components/ui/molecules/Button';
import TextInput from 'components/ui/molecules/TextInput';
import { useGTM } from 'hooks/gtm';
import { useContext, useRef, useState } from 'react';
import { Tooltip } from 'shared/components/Tooltip';
import { CandidateContext } from 'shared/contexts/CandidateContext/CandidateContext';
import { ConfigContext } from 'shared/contexts/ConfigContext/ConfigContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useContextSelector } from 'use-context-selector';

const ShareButton = () => {
  const { dictionary } = useContext(LocalizationContext);
  const [shareText, setShareText] = useState(dictionary.copyLinkCandidateProfile);
  const [shouldShowCandidateLink, setShouldShowCandidateLink] = useState(false);
  const onShare = useContextSelector(CandidateContext, (state) => state.onShare);
  const campaignId = useContextSelector(CandidateContext, (state) => state.campaignId);
  const candidateEsPersonId = useContextSelector(CandidateContext, (state) => state.candidateEsPersonId);
  const { currentUser: { id: currentUserId = undefined } = {} } = useContext(UserContext);
  const gtm = useGTM();
  const isLoadingSkeleton = false;
  const inputRef = useRef<HTMLDivElement>(null);

  const handleShare = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    try {
      const shareText = await onShare();
      if (!shareText) {
        throw new Error('Share text cannot be empty');
      }

      await navigator.clipboard.writeText(shareText);

      campaignId && gtm.copiedUrlToShareCandidate({ campaignId, esPersonId: candidateEsPersonId });
      setShareText(dictionary.linkCopySuccess);
    } catch {
      setShareText(dictionary.linkCopyFail);
    } finally {
      setTimeout(() => {
        setShareText(dictionary.copyLinkCandidateProfile);
      }, 3000);
    }
  };

  const handleShowCandidateLink = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShouldShowCandidateLink(true);

    setTimeout(() => {
      if (window.getSelection) {
        const selection = window.getSelection();

        if (selection && inputRef.current) {
          const range = document.createRange();
          range.selectNodeContents(inputRef.current);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }, 16);
  };

  const candidateLink = `${window.location.origin}/shared/u/${currentUserId}/s/${campaignId}/c/${candidateEsPersonId}`;

  const isIframe = useContextSelector(ConfigContext, (state) => state.isIframe);

  return (
    <Row gap={8} alignCenter>
      {shouldShowCandidateLink && (
        <Tooltip title={dictionary.youCanCopyTheLinkPressingCtrlC} placement="bottom-start">
          <Row alignCenter gap={5}>
            <span>
              <TextInput text={candidateLink} onChange={() => null} disabled ref={inputRef} />
            </span>
            <Button
              onClick={() => setShouldShowCandidateLink(false)}
              tooltipLabel={dictionary.close}
              variant="small-rounded"
              icon={<XClose />}
            />
          </Row>
        </Tooltip>
      )}

      <IconButton
        icon={isLoadingSkeleton ? <Loader size={16} color={(c) => c.secondary.sky} /> : <Link size={16} />}
        onClick={(e) => (isIframe ? handleShowCandidateLink(e) : handleShare(e))}
        style="hover"
        type="default"
        label={shareText}
        data-tutorial-id="share"
      />
    </Row>
  );
};

export default ShareButton;

import { Row } from 'components/ui/atoms';
import { ShouldCallWerknlOutreachDialog } from '../ShouldCallWerknlOutreachDialog/ShouldCallWerknlOutreachDialog';
import useContainer from './CandidateOutreachButton.useContainer';

const CandidateOutreachButton = () => {
  const { isWerknlModalVisible, onConfirmWerknlModal, availableOutreachMethodsButtons } = useContainer();

  return (
    <Row gap={8}>
      {availableOutreachMethodsButtons}
      <ShouldCallWerknlOutreachDialog isVisible={isWerknlModalVisible} onConfirm={onConfirmWerknlModal} />
    </Row>
  );
};

export default CandidateOutreachButton;

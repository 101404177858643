import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { CANDIDATE_ACTION } from 'services/candidates/types';
import { CandidateContext } from 'shared/contexts/CandidateContext/CandidateContext';
import { SelectedCampaignContext } from 'shared/contexts/SelectedCampaignContext';
import { CandidateStatus } from 'types/candidate';
import { useContextSelector } from 'use-context-selector';

export const useContainer = () => {
  const pageContext = usePageContext();
  const isSharedOrCandidatePage = pageContext === PageContext.Shared || pageContext === PageContext.Candidate;
  const availableActions = useContextSelector(CandidateContext, (state) => state.availableActions);
  const candidateStatus = useContextSelector(CandidateContext, (state) => state.searchv3.candidateUIState.status);

  const onShortlist = useContextSelector(CandidateContext, (state) => state.onShortlist);
  const onMatch = useContextSelector(CandidateContext, (state) => state.onApprove);
  const onHide = useContextSelector(CandidateContext, (state) => state.onReject);
  const isShortlistLoading = useContextSelector(CandidateContext, (state) => state.isLoading.shortlist);
  const isMatchLoading = useContextSelector(CandidateContext, (state) => state.isLoading.approve);
  const isHideLoading = useContextSelector(CandidateContext, (state) => state.isLoading.reject);
  const availableOutreachMethods = useContextSelector(CandidateContext, (state) => state.availableOutreachMethods);
  const selectedCandidate = useContextSelector(SelectedCampaignContext, (state) => state.selectedCandidate);

  const setActiveOutreachModal = (_: string) => _;

  const onCall = () => setActiveOutreachModal('call');
  const onEmail = () => setActiveOutreachModal('email');

  const hasContactMethodsAvailable = !!availableOutreachMethods.length;

  const shouldShowActionButton = {
    contact: availableActions.actions.includes(CANDIDATE_ACTION.CONTACT) && hasContactMethodsAvailable,
    divider: availableActions.actions.includes(CANDIDATE_ACTION.CONTACT) && hasContactMethodsAvailable,

    shortlist: availableActions.actions.includes(CANDIDATE_ACTION.SHORTLIST) && isSharedOrCandidatePage,
    match: availableActions.actions.includes(CANDIDATE_ACTION.MATCH),
    downloadProfile: availableActions.actions.includes(CANDIDATE_ACTION.DOWNLOAD),
    hide: availableActions.actions.includes(CANDIDATE_ACTION.HIDE) && isSharedOrCandidatePage,
    showMore: availableActions.actions.includes(CANDIDATE_ACTION['SHOW-MORE']),
    shareCandidate: availableActions.actions.includes(CANDIDATE_ACTION['SHARE-PROFILE']),
  };

  const showShortCTAButtonOnCandidateCard = !!selectedCandidate;

  const showShortlistButton = [CandidateStatus.New, CandidateStatus.Hidden].includes(candidateStatus);
  const showHideButton = [CandidateStatus.New, CandidateStatus.Shortlisted, CandidateStatus.Contacted].includes(
    candidateStatus,
  );

  return {
    isMatchLoading,
    onShortlist,
    onMatch,
    onHide,
    onCall,
    onEmail,
    isShortlistLoading,
    isHideLoading,
    shouldShowActionButton,
    showShortCTAButtonOnCandidateCard,
    candidateStatus,
    showShortlistButton,
    showHideButton,
  };
};

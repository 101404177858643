import { IconButton, Loader } from '@recruitrobin/robin-theme/web-components';
import { Bookmark, Download_01, UserCheck_01, XClose } from '@recruitrobin/robin-theme/web-icons';
import { Row } from 'components/ui/atoms';
import { ReactNode, useCallback, useContext, useMemo } from 'react';
import { CandidateAction } from 'services/candidates/types';
import { CandidateContext } from 'shared/contexts/CandidateContext/CandidateContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useCandidateDownloadCvMutation } from 'shared/hooks/useCandidateDownloadCvMutation';
import { CandidateStatus } from 'types/candidate';
import { useContextSelector } from 'use-context-selector';
import { useStyles } from '../CandidateDetailsHeaderInfo/styles';
import CandidateOutreachButton from '../CandidateOutreachButton';
import { useContainer } from './CandidateActionButtons.useContainer';
import ShareButton from './ShareButton';
import ShowMoreButton from './ShowMoreButton';

type CandidateActionLayout = CandidateAction | 'DIVIDER';
type ButtonsLayout = Record<CandidateStatus, Array<CandidateActionLayout>>;

const useLayout = () => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);

  const {
    showShortCTAButtonOnCandidateCard,
    candidateStatus,
    isShortlistLoading,
    onShortlist,
    isMatchLoading,
    onMatch,
    isHideLoading,
    onHide,
  } = useContainer();
  const availableActions = useContextSelector(CandidateContext, (state) => state.availableActions);
  const availableOutreachMethods = useContextSelector(CandidateContext, (state) => state.availableOutreachMethods);
  const candidate = useContextSelector(CandidateContext, (state) => state.candidate);
  const downloadCvMutation = useCandidateDownloadCvMutation(candidate!);

  const contactButtonWithDivider: CandidateActionLayout[] = availableOutreachMethods.length
    ? ['CONTACT', 'DIVIDER']
    : [];

  const candidateDetailsLayout: ButtonsLayout = {
    NEW: [...contactButtonWithDivider, 'SHOW-MORE'],
    SHORTLISTED: [...contactButtonWithDivider, 'SHOW-MORE'],
    CONTACTED: [...contactButtonWithDivider, 'MATCH', 'SHARE-PROFILE'],
    MATCHED: [...contactButtonWithDivider, 'DOWNLOAD', 'SHOW-MORE'],
    HIDDEN: ['SHARE-PROFILE', 'MATCH'],
  };

  const candidateCVExpandedViewLayout: ButtonsLayout = {
    NEW: ['SHOW-MORE', 'DIVIDER', ...contactButtonWithDivider, 'SHORTLIST', 'HIDE'],
    SHORTLISTED: ['SHOW-MORE', 'DIVIDER', ...contactButtonWithDivider, 'HIDE'],
    CONTACTED: ['SHARE-PROFILE', 'MATCH', 'DIVIDER', ...contactButtonWithDivider, 'HIDE'],
    MATCHED: ['SHOW-MORE', 'DOWNLOAD', 'DIVIDER', 'CONTACT'],
    HIDDEN: ['MATCH', 'SHARE-PROFILE', 'DIVIDER', 'SHORTLIST'],
  };

  const candidateCardLayout: ButtonsLayout = useMemo(
    () => ({
      NEW: showShortCTAButtonOnCandidateCard
        ? ['SHORTLIST', 'HIDE']
        : candidateCVExpandedViewLayout[CandidateStatus.New],
      SHORTLISTED: showShortCTAButtonOnCandidateCard
        ? ['HIDE']
        : candidateCVExpandedViewLayout[CandidateStatus.Shortlisted],
      CONTACTED: showShortCTAButtonOnCandidateCard
        ? ['HIDE']
        : candidateCVExpandedViewLayout[CandidateStatus.Contacted],
      MATCHED: showShortCTAButtonOnCandidateCard ? [] : candidateCVExpandedViewLayout[CandidateStatus.Matched],
      HIDDEN: showShortCTAButtonOnCandidateCard ? ['SHORTLIST'] : candidateCVExpandedViewLayout[CandidateStatus.Hidden],
    }),
    [showShortCTAButtonOnCandidateCard, candidateCVExpandedViewLayout],
  );

  const actionButtons: Record<CandidateActionLayout, ReactNode> = useMemo(
    () => ({
      CONTACT: <CandidateOutreachButton />,
      SHORTLIST: (
        <IconButton
          icon={isShortlistLoading ? <Loader size={16} color={(c) => c.secondary.sky} /> : <Bookmark size={16} />}
          onClick={(e) => {
            e.stopPropagation();
            !isShortlistLoading && onShortlist();
          }}
          style="hover"
          type="default"
          label={dictionary.shortlist}
        />
      ),
      MATCH: (
        <IconButton
          icon={isMatchLoading ? <Loader size={16} /> : <UserCheck_01 size={16} />}
          onClick={(e) => {
            e.stopPropagation();
            !isMatchLoading && onMatch();
          }}
          style="hover"
          type="highlight"
          label={dictionary.match}
        />
      ),
      HIDE: (
        <IconButton
          icon={isHideLoading ? <Loader size={14} color={(c) => c.tertiary.lime} /> : <XClose size={14} />}
          onClick={(e) => {
            e.stopPropagation();
            !isHideLoading && onHide();
          }}
          style="hover"
          type="warning"
          label={dictionary.hide}
        />
      ),
      DOWNLOAD: (
        <IconButton
          icon={
            downloadCvMutation.isLoading ? (
              <Loader size={16} color={(c) => c.secondary.sky} />
            ) : (
              <Download_01 size={16} />
            )
          }
          onClick={(e) => {
            e.stopPropagation();
            !downloadCvMutation.isLoading && downloadCvMutation.mutateAsync();
          }}
          style="hover"
          type="default"
          label={dictionary.downloadProfile}
        />
      ),
      'SHOW-MORE': <ShowMoreButton secondaryActions={availableActions.extraOptions['SHOW-MORE'] || []} />,
      'SHARE-PROFILE': <ShareButton />,
      DIVIDER: <div css={classes.divider} />,
    }),
    [
      isShortlistLoading,
      isMatchLoading,
      isHideLoading,
      downloadCvMutation,
      availableActions,
      dictionary,
      onShortlist,
      onMatch,
      onHide,
    ],
  );

  const renderActions = useCallback(
    (actions: Array<CandidateActionLayout>, callback?: VoidFunction): ReactNode | undefined => {
      if (actions.length === 0) {
        return undefined;
      }
      return actions.map((action, idx) => {
        return (
          <div key={`action-button-${action}-${idx}`} onClick={callback && callback}>
            {actionButtons[action]}
          </div>
        );
      });
    },
    [actionButtons],
  );

  const applyLayout = useCallback(
    (layout: ButtonsLayout, callback?: VoidFunction) => {
      const actions = layout[candidateStatus];
      const renderedActions = renderActions(actions, callback);

      return (
        !!renderedActions && (
          <Row inline alignCenter css={classes.actionButtonsWrapper} onClick={(e) => e.stopPropagation()}>
            {renderedActions}
          </Row>
        )
      );
    },
    [candidateStatus, renderActions],
  );

  return {
    candidateDetails: () => applyLayout(candidateDetailsLayout),
    candidateCVExpandedView: (callback?: VoidFunction) => applyLayout(candidateCVExpandedViewLayout, callback),
    candidateCard: () => applyLayout(candidateCardLayout),
  };
};

export default useLayout;

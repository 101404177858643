import { Row } from 'components/ui/atoms';
import { useStyles } from '../CandidateDetailsHeaderInfo/styles';
import Skeleton from '../Skeleton';
import { CandidateActionButtonsProps, CandidateActionButtonsVariant } from './CandidateActionButtons.types';
import useLayout from './CandidateActionButtons.useLayout';

const CandidateActionButtons = ({ isLoading = false, variant, callback }: CandidateActionButtonsProps) => {
  const classes = useStyles();
  const { candidateDetails, candidateCard, candidateCVExpandedView } = useLayout();

  if (isLoading) {
    return (
      <Row css={classes.actionButtonsWrapper}>
        <Skeleton variant="square" width={34} height={34} />
        <Skeleton variant="square" width={34} height={34} />
      </Row>
    );
  }

  switch (variant) {
    case CandidateActionButtonsVariant.candidateCard:
      return candidateCard();
    case CandidateActionButtonsVariant.candidateExpandedView:
      return candidateCVExpandedView(callback);
    case CandidateActionButtonsVariant.candidateDetails:
    default:
      return candidateDetails();
  }
};

export default CandidateActionButtons;

import type { default as Candidate } from 'model/Candidate/Candidate';

import { useMutation } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';

export const useCandidateDownloadCvMutation = (candidate: Candidate) => {
  return useMutation(async () => {
    const fileExtension = candidate.cvFileId ? candidate.cvFileId.split('.').toReversed()[0] : 'pdf';

    const response = await campaignsClient.downloadCampaignCandidateCV(
      candidate.campaignId,
      candidate.es_person_id,
      candidate.name,
      fileExtension,
    );

    return response;
  });
};

import { Candidate } from 'model';

export type CandidateCVProps = {
  candidate: Candidate;
  cv: CvResponse;
};

export enum RequestCvErrorType {
  notAvailable = 'notAvailable',
  formatIssues = 'formatIssues',
}

export type CvResponse =
  | { isPdf: true; data: Blob; error?: never }
  | { isPdf: false; data: string | null; error?: RequestCvErrorType };

export type UseRequestCvHandler = (candidate: Candidate) => CvResponse;

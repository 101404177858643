import { useLocalStorage } from 'hooks/shared/useLocalStorage';
import { useContext, useEffect, useMemo } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { SelectedCampaignContext } from '../SelectedCampaignContext';
import { SidebarOverlayFixedContext } from '../SidebarOverlayFixedContext';
import { CandidateOutreachModalContext } from '../CandidateOutreachModalContext';

type CandidateDetailsVisibilityContextProps = {
  children: React.ReactNode;
};

type CandidateDetailsVisibilityState = {
  shouldOpenCandidateDetails: boolean;
  isCandidateDetailsOpened: boolean;
  setShouldOpenCandidateDetails: (newValue: boolean) => void;
};

const initalState: CandidateDetailsVisibilityState = {
  shouldOpenCandidateDetails: false,
  isCandidateDetailsOpened: false,
  setShouldOpenCandidateDetails: () => undefined,
};

export const CandidateDetailsVisibilityContext = createContext(initalState);

export const CandidateDetailsVisibilityContextProvider = ({ children }: CandidateDetailsVisibilityContextProps) => {
  const selectedCandidate = useContextSelector(SelectedCampaignContext, (state) => state.selectedCandidate);
  const setSelectedCandidate = useContextSelector(SelectedCampaignContext, (state) => state.setSelectedCandidate);
  const { data: candidates } = useContextSelector(SelectedCampaignContext, (state) => state.candidates);
  const { isOpen: isFixedSidebarOpen, setIsOpen: setIsFixedSidebarOpen } = useContext(SidebarOverlayFixedContext);
  const { value: shouldOpenCandidateDetails, setValue: setShouldOpenCandidateDetails } = useLocalStorage<boolean>(
    'candidate-details-opened',
    initalState.shouldOpenCandidateDetails,
  );
  const isCandidateOutreachModalVisible = useContextSelector(
    CandidateOutreachModalContext,
    (s) => !!s.candidateOutreachModalRef,
  );

  useEffect(() => {
    if (isCandidateOutreachModalVisible) {
      setIsFixedSidebarOpen(false);
    }
  }, [isCandidateOutreachModalVisible]);

  useEffect(() => {
    if (selectedCandidate && !shouldOpenCandidateDetails) {
      setShouldOpenCandidateDetails(true);
      setIsFixedSidebarOpen(false);
    }
  }, [selectedCandidate]);

  useEffect(() => {
    if (isFixedSidebarOpen) {
      setSelectedCandidate(undefined);
      setShouldOpenCandidateDetails(false);
    }
  }, [isFixedSidebarOpen]);

  useEffect(() => {
    if (
      candidates !== undefined &&
      candidates.length > 0 &&
      shouldOpenCandidateDetails &&
      selectedCandidate === undefined
    ) {
      setSelectedCandidate(candidates[0]);
    }
  }, [candidates, shouldOpenCandidateDetails, selectedCandidate]);

  const state = useMemo<CandidateDetailsVisibilityState>(
    () => ({
      shouldOpenCandidateDetails,
      isCandidateDetailsOpened: !!selectedCandidate && shouldOpenCandidateDetails,
      setShouldOpenCandidateDetails,
    }),
    [shouldOpenCandidateDetails, selectedCandidate],
  );

  return (
    <CandidateDetailsVisibilityContext.Provider value={state}>{children}</CandidateDetailsVisibilityContext.Provider>
  );
};

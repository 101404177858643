export enum CandidateActionButtonsVariant {
  candidateDetails = 'CANDIDATE-DETAILS',
  candidateCard = 'CANDIDATE-CARD',
  candidateExpandedView = 'CANDIDATE-EXPANDED-VIEW',
}

export type CandidateActionButtonsProps = {
  isLoading?: boolean;
  isCandidateCard?: boolean;
  variant?: CandidateActionButtonsVariant;
  callback?: VoidFunction;
};

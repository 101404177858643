export const removeUnnecessaryBreakLines = (text: string): string => {
  let breakLineCount = 0;

  const newText = text.split('\n').reduce((finalString, currentPart) => {
    if (currentPart === '') {
      breakLineCount++;

      return finalString;
    }

    const corretBreakLineCount = Math.floor(breakLineCount / 2) + 1;
    const breakLines = Array(corretBreakLineCount).fill('\n').join('');

    breakLineCount = 0;

    return finalString + breakLines + currentPart;
  }, '');

  return newText;
};

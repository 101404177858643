import type { ModalProps } from './types';

import { ClassNames } from '@emotion/react';
import { IconButton } from '@recruitrobin/robin-theme/web-components';
import { XClose } from '@recruitrobin/robin-theme/web-icons';
import { default as ReactModal } from 'react-modal';
import { MODAL_TRANSITION_DURATION_MS, useStyles } from './styles';

const Modal = ({
  overlayCss,
  className,
  children,
  visible = false,
  sideModal = false,
  onRequestClose,
  hideCloseButton = false,
  overlayRef,
  contentCss,
}: ModalProps) => {
  const classes = useStyles({ sideModal });

  return (
    <ClassNames>
      {({ css, cx }) => (
        <ReactModal
          shouldCloseOnOverlayClick
          isOpen={visible}
          onRequestClose={onRequestClose}
          bodyOpenClassName={css(classes.body)}
          overlayClassName={cx(css(classes.overlay), sideModal && css(classes.sideModal), css(overlayCss))}
          overlayRef={overlayRef}
          className={cx(css(classes.content), className, css(contentCss))}
          closeTimeoutMS={MODAL_TRANSITION_DURATION_MS}
          preventScroll>
          {!hideCloseButton && (
            <div css={classes.closeModalIcon}>
              <IconButton
                onClick={() => onRequestClose?.()}
                icon={<XClose size={14} color={(c) => c.neutral.variant[70]} />}
                style={'hover'}
                type={'subtle'}
              />
            </div>
          )}

          {children}
        </ReactModal>
      )}
    </ClassNames>
  );
};

export default Modal;

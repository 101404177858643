import { useContext, type ChangeEvent } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { usePreventScroll } from 'shared/hooks/usePreventScroll';
import { ShouldCallWerknlOutreachDialogProps } from './ShouldCallWerknlOutreachDialog.types';
import { useStyles } from './ShouldCallWerknlOutreachDialog.styles';
import { Dialog } from '@recruitrobin/robin-theme/web-components';
import { Checkbox } from 'shared/components/CheckboxGroup';
import { useLocalStorage } from 'hooks/shared';

export const DISMISS_SHOULD_CALL_WERKNL_MODAL = 'dismiss-should-call-werknl-modal';

export const ShouldCallWerknlOutreachBody = () => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);

  const { setValue: setDismiss } = useLocalStorage(DISMISS_SHOULD_CALL_WERKNL_MODAL);

  const handleToogleDismissModal = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    setDismiss(checked);
  };

  return (
    <div css={classes.shouldCallWerknlOutreachBody}>
      {dictionary.shouldCallWerknlOutreachModal}
      <Checkbox label={dictionary.dontShowThisAgain} onChange={handleToogleDismissModal} />
    </div>
  );
};

export const ShouldCallWerknlOutreachDialog = ({ isVisible, onConfirm }: ShouldCallWerknlOutreachDialogProps) => {
  const { dictionary } = useContext(LocalizationContext);

  usePreventScroll(isVisible);

  return (
    <Dialog
      isVisible={isVisible}
      onConfirm={onConfirm}
      title={dictionary.shouldCallWerknlOutreachTitle}
      body={<ShouldCallWerknlOutreachBody />}
      confirmLabel={dictionary.done}
      maxWidth={500}
    />
  );
};

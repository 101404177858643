import { useCallback, useContext, useMemo, useState } from 'react';

import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { default as Select } from 'shared/components/Select';
import { paramBind } from 'helpers/useQueryHelpers';

import { SearchCampaignsOptions, SearchCampaignsProps } from './types';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from 'react-use';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';

/**
 * @deprecated Do not use this component as reference, it's just here because
 * the ApproveCandidateToAnotherSearchModal componente itself was made using outdated dependencies
 * so it's easier to create a skeleton from scratch than refactor it to handle loadings
 */
export const SearchCampaigns = ({ handleChange: handleParentChange }: SearchCampaignsProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const [value, setValue] = useState<number>();
  const [search, setSearch] = useState('');
  const [searchDebounced, setSearchDebounced] = useState('');

  useDebounce(() => setSearch(searchDebounced), 250, [searchDebounced]);

  const { data: { data: { results: options = [] } = {} } = {} } = useQuery(
    ['getMyCampaigns', { limit: 10, offset: 0, name: search }],
    paramBind(campaignsClient.getCampaignsPage),
  );

  const handleChange = useCallback((searchCampaign: SearchCampaignsOptions) => {
    setValue(searchCampaign.value);
    handleParentChange(searchCampaign.value);
    setSearchDebounced(searchCampaign.title);
  }, []);

  const handleInputChange = useCallback((value: string) => {
    setSearchDebounced(value);
  }, []);

  const formattedOptions = useMemo(() => {
    return options.map<SearchCampaignsOptions>(({ id, name }) => ({ value: id, title: name }));
  }, [options]);

  const formattedValue = useMemo(() => {
    return formattedOptions.find((option) => option.value === value);
  }, [formattedOptions]);

  return (
    <Select
      tooltipLabel={dictionary.searchForCampaign}
      label={dictionary.searchForCampaign}
      options={formattedOptions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      value={formattedValue}
      textValue={searchDebounced}
      chipType="menu"
      typeable
      contained
      allowParentTextValue
    />
  );
};

import { keyframes } from '@emotion/core';
import { css, extractColor, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { generateTransitionStyles } from 'utils/style';
import { JobBoardIconSize, JobBoardIconStyleStates } from './types';

export const jobBoardIconSizes: Record<JobBoardIconSize, number> = {
  small: 24,
  medium: 30,
  big: 40,
};

const isActivatingAnimation = keyframes`
  from {
    transform: rotateZ(0deg) scale(1);
  }
  50% {
    transform: rotateZ(180deg) scale(0.8);
  }
  to {
    transform: rotateZ(360deg) scale(1);
  }
`;

const styles = {
  wrapper: ({ theme, props: { size, pointer } }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: jobBoardIconSizes[size],
      height: jobBoardIconSizes[size],
      backgroundColor: theme.colors.neutral.variant[0],
      borderRadius: '50%',
      overflow: 'hidden',
      boxSizing: 'content-box',
      ...(pointer && { cursor: 'pointer' }),
    }),
  content: ({ theme, props: { size, disabled, isActivating, backgroundColor } }) =>
    css({
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: jobBoardIconSizes[size],
      height: jobBoardIconSizes[size],
      color: disabled ? theme.colors.neutral.variant[0] : theme.colors.primary.fuchsia[50],
      borderRadius: '50%',
      backgroundColor: disabled ? theme.colors.neutral.variant[30] : theme.colors.primary.fuchsia[20],
      transition: generateTransitionStyles('opacity', (d) => d.long),
      '&:hover': {
        backgroundColor: disabled ? theme.colors.neutral.variant[50] : theme.colors.primary.fuchsia[30],
        color: disabled ? theme.colors.neutral.variant[0] : theme.colors.primary.fuchsia[60],
      },
      ...(backgroundColor && {
        backgroundColor: extractColor(backgroundColor),
      }),
      ...(disabled && {
        backgroundColor: theme.colors.neutral.variant[30],
      }),
      ...(isActivating && {
        animationName: isActivatingAnimation,
        animationDuration: '1000ms',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
      }),
    }),
} satisfies Styles<JobBoardIconStyleStates>;

export const useStyles = withStyles(styles);

import type { default as Candidate } from 'model/Candidate';
import type { UseActionsHookReturnType } from 'shared/contexts/CandidateContext/types';
import type { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import type { CandidateStatus } from 'types/candidate';

export enum CandidateDetailsTab {
  CV = 'cv',
  details = 'details',
  activity = 'activity',
}

export type CandidateDetailsConfig = {
  initialTab: CandidateDetailsTab;
};

export type CandidateConfig = {
  candidateDetails: CandidateDetailsConfig;
};

export type SearchV3CandidateUIState = {
  isSelected: boolean;
  isLoading: boolean;
  status: CandidateStatus;
};

export type SearchV3CandidateResponse = Pick<Candidate, 'key' | 'name' | 'es_person_id'> & {
  select: (config?: DeepPartial<CandidateConfig>) => void;
  bindMatchesAndGetJobboards: (matches: CandidateMatches) => CandidateJobboardsWithActiveState[];

  experiences: CandidateExperience[];
  preferences: CandidatePreferences[];
  education?: CandidateEducation[];
  location?: CandidateLocation;
  lastRefresh?: string;
};

export type SearchV3CandidateStatefulResponse = {
  config: CandidateConfig;
  candidateUIState: SearchV3CandidateUIState;
  candidate: SearchV3CandidateResponse;
};

export type Searchv3CandidateHandler = (
  candidate: Candidate | undefined,
  config: { actions: UseActionsHookReturnType; config?: CandidateConfig },
) => SearchV3CandidateStatefulResponse;

/**
 * Types mapped for frontend use
 */

export type CandidateLocation = { name: string; distance?: string };

export type CandidatePreferences = { id: number; text: string; meta: string };

export type CandidateExperience = {
  position: string;
  company?: string;
  date: string;
  description?: string;
  timeInMonths?: string;
};

export type CandidateEducation = {
  field: string;
  school: string;
  date: string;
  description?: string;
  fullDuration?: string | null;
};

export type CandidateMatches = Record<string, string[] | undefined>;

export type CandidateJobboardsWithActiveState = [CampaignJobboard, boolean];

import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () => css`
    height: 100%;
    width: 100%;
    gap: 0;
  `,
  pdfWrapper: () => css`
    position: relative;
    height: 100%;
    max-width: 1000px;
    overflow-x: hidden;
    overflow-y: auto;
    justify-content: center;
    gap: 0;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    & button {
      border: none;
      background: none;
      cursor: pointer;
    }

    & > div:first-of-type {
      & .react-pdf__Page__textContent {
        user-select: none;
      }
    }
  `,
  pdfWrapperCvFile: css`
    justify-content: flex-start;
    margin-top: 0;
    margin-left: 0;
  `,
  expandedCvViewButtonWrapper: css`
    position: absolute;
    right: 6px;
  `,
  expandedCvViewModal: css`
    justify-content: center;
    display: flex;
    bottom: 0;
    top: 45px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transform: translateX(-50%);
    padding: 14px 19px 0px 30px;
    overflow: hidden;
  `,
  expandedCvViewWrapper: css`
    display: flex;
    flex-direction: column;
    min-width: 800px;
    max-width: 1000px;
    width: fit-content;
    gap: 14px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);

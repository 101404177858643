import { Candidate } from 'model';
import { useEffect, useState } from 'react';

export const useSelectedCandidate = (candidates: Candidate[], isCandidatesLoading: boolean) => {
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate>();

  useEffect(() => {
    if (isCandidatesLoading) {
      setSelectedCandidate(undefined);
    }
  }, [isCandidatesLoading]);

  useEffect(() => {
    const hasSelectedCandidateInList =
      selectedCandidate && candidates.some((c) => c.es_person_id === selectedCandidate.es_person_id);

    if (selectedCandidate && !hasSelectedCandidateInList && !isCandidatesLoading) {
      setSelectedCandidate(undefined);
    }
  }, [candidates, selectedCandidate, isCandidatesLoading]);

  useEffect(() => {
    if (selectedCandidate) {
      const changedCandidate = candidates.find((c) => c.es_person_id === selectedCandidate.es_person_id);

      if (changedCandidate && changedCandidate.internal_id !== selectedCandidate.internal_id) {
        setSelectedCandidate(changedCandidate);
      }
    }
  }, [candidates]);

  return { selectedCandidate, setSelectedCandidate };
};

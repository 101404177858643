import { DotsHorizontal, XClose } from '@recruitrobin/robin-theme/web-icons';
import { Row } from 'components/ui/atoms';
import { default as Button } from 'components/ui/molecules/Button';
import { default as DropdownButton } from 'components/ui/molecules/DropdownButton';
import TextInput from 'components/ui/molecules/TextInput';
import ApproveCandidateToAnotherSearchModal from 'components/ui/organisms/ApproveCandidateToAnotherSearchModal';
import { useGTM } from 'hooks/gtm';
import { useContext, useRef, useState } from 'react';
import { CandidateSecondaryAction } from 'services/candidates/types';
import { Tooltip } from 'shared/components/Tooltip';
import { CandidateContext } from 'shared/contexts/CandidateContext/CandidateContext';
import { ConfigContext } from 'shared/contexts/ConfigContext/ConfigContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useContextSelector } from 'use-context-selector';
import { useSecondaryActionsToMenuOptions } from './useSecondaryActionsToMenuOptions';

const ShowMoreButton = ({ secondaryActions }: { secondaryActions: CandidateSecondaryAction[] }) => {
  const isLoadingSkeleton = false;

  const gtm = useGTM();
  const { dictionary } = useContext(LocalizationContext);
  const [shouldShowCandidateLink, setShouldShowCandidateLink] = useState(false);

  const [isShortListToAnotherSearchModalVisible, setIsShortListToAnotherSearchModalVisible] = useState(false);
  const isIframe = useContextSelector(ConfigContext, (state) => state.isIframe);
  const inputRef = useRef<HTMLDivElement>(null);
  const { currentUser: { id: currentUserId = undefined } = {} } = useContext(UserContext);

  const options = useSecondaryActionsToMenuOptions(secondaryActions);
  const { createSnackbar } = useContext(SnackbarContext);
  const campaignId = useContextSelector(CandidateContext, (state) => state.campaignId);
  const candidateName = useContextSelector(CandidateContext, (state) => state.candidateName);
  const candidateEsPersonId = useContextSelector(CandidateContext, (state) => state.candidateEsPersonId);
  const onApprove = useContextSelector(CandidateContext, (state) => state.onApprove);
  const onShortlist = useContextSelector(CandidateContext, (state) => state.onShortlist);
  const onResendAts = useContextSelector(CandidateContext, (state) => state.onResendAts);
  const onShare = useContextSelector(CandidateContext, (state) => state.onShare);

  const isShortlistLoading = useContextSelector(CandidateContext, (state) => state.isLoading.shortlist);
  const handleShortListToAnotherSearch = (searchId: number) =>
    onShortlist(searchId)
      .then(() => createSnackbar(dictionary.candidateSuccessfullyShortlistedToAnotherSearch))
      .catch(() => createSnackbar(dictionary.somethingWentWrong));

  const handleRetryToSendToAts = () => onResendAts();
  const handleMatch = () => onApprove();

  const handleOpenShortListToAnotherSearchModal = () => setIsShortListToAnotherSearchModalVisible(true);
  const handleCloseShortListToAnotherSearchModal = () => setIsShortListToAnotherSearchModalVisible(false);
  const handleShare = async () => {
    const shareText = await onShare();
    if (!shareText) {
      throw new Error('Share text cannot be empty');
    }

    await navigator.clipboard.writeText(shareText);
    campaignId && gtm.copiedUrlToShareCandidate({ campaignId, esPersonId: candidateEsPersonId });
  };

  const candidateLink = `${window.location.origin}/shared/u/${currentUserId}/s/${campaignId}/c/${candidateEsPersonId}`;

  const handleShowCandidateLink = async () => {
    setShouldShowCandidateLink(true);

    setTimeout(() => {
      if (window.getSelection) {
        const selection = window.getSelection();

        if (selection && inputRef.current) {
          const range = document.createRange();
          range.selectNodeContents(inputRef.current);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }, 16);
  };

  const callbacks: Record<CandidateSecondaryAction, () => void> = {
    'RETRY-TO-SEND-TO-ATS': handleRetryToSendToAts,
    'SHORTLIST-TO-ANOTHER-SEARCH': handleOpenShortListToAnotherSearchModal,
    MATCH: handleMatch,
    'SHARE-PROFILE': isIframe ? handleShowCandidateLink : handleShare,
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {shouldShowCandidateLink && (
        <Tooltip title={dictionary.youCanCopyTheLinkPressingCtrlC} placement="bottom-start">
          <Row alignCenter gap={5}>
            <span>
              <TextInput text={candidateLink} onChange={() => null} disabled ref={inputRef} />
            </span>
            <Button
              onClick={() => setShouldShowCandidateLink(false)}
              tooltipLabel={dictionary.close}
              variant="small-rounded"
              icon={<XClose />}
            />
          </Row>
        </Tooltip>
      )}
      <DropdownButton
        style="icon"
        tooltipLabel={dictionary.more}
        icon={<DotsHorizontal size={16} />}
        isLoading={isLoadingSkeleton}
        menuOptions={options}
        onSelectOption={(opt) => callbacks[opt.key]()}
        contextMenuAlignment="left"
        data-tutorial-id="more"
      />

      {secondaryActions.includes('SHORTLIST-TO-ANOTHER-SEARCH') && (
        <ApproveCandidateToAnotherSearchModal
          candidateName={candidateName}
          visible={isShortListToAnotherSearchModalVisible}
          onRequestClose={handleCloseShortListToAnotherSearchModal}
          onSend={handleShortListToAnotherSearch}
          onApproveIsLoading={isShortlistLoading}
          isLoading={isLoadingSkeleton}
        />
      )}
    </div>
  );
};

export default ShowMoreButton;

import { useEffect, useRef, useState } from 'react';
import { DropdownButtonProps } from './types';
import { useStyles } from './styles';
import { Row } from 'components/ui/atoms';
import { default as Button, ButtonProps } from 'components/ui/molecules/Button';
import { default as PortalWrapper } from 'components/ui/molecules/PortalWrapper';
import { ContextMenuOption, default as ContextMenu } from 'components/ui/molecules/ContextMenu';

import * as Icons from 'components/ui/atoms/icons';

const DropdownButton = <T extends ContextMenuOption>({
  icon,
  menuOptions,
  onSelectOption,
  onClick,
  contextMenuAlignment,
  ...forwardButtonProps
}: DropdownButtonProps<T>) => {
  const classes = useStyles();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  useEffect(() => {
    if (isContextMenuOpen) {
      const button = buttonRef.current;

      if (button) {
        button.classList.add('m-active');

        return () => button.classList.remove('m-active');
      }
    }
  }, [isContextMenuOpen]);

  const handleOpenContextMenu = () => setIsContextMenuOpen(true);
  const handleCloseContextMenu = () => setIsContextMenuOpen(false);
  const handleSelectOption = (opt: T) => {
    onSelectOption(opt);
    setIsContextMenuOpen(false);
  };

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    onClick?.();
  };

  const handleContextMenu: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isContextMenuOpen) {
      handleCloseContextMenu();
    } else {
      handleOpenContextMenu();
    }
  };

  return (
    <Row css={classes.root} inline>
      <Button
        {...(forwardButtonProps as ButtonProps)}
        ref={buttonRef}
        onClick={onClick ? handleClick : handleContextMenu}
        onContextMenu={handleContextMenu}
        icon={
          typeof icon === 'object'
            ? icon
            : typeof icon === 'string'
              ? Icons[icon as Icons.IconKey]({ color: 'currentColor', size: 16 })
              : undefined
        }
      />

      <PortalWrapper
        anchorEl={buttonRef}
        onRequestClose={handleCloseContextMenu}
        visible={isContextMenuOpen}
        alignment={contextMenuAlignment}>
        <ContextMenu onChange={handleSelectOption} options={menuOptions} />
      </PortalWrapper>
    </Row>
  );
};

export default DropdownButton;

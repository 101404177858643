import { IMCandidate } from 'model/Candidate';
import type { Dictionary } from 'shared/contexts/LocalizationContext/types';
import { CandidateEducation } from './types';

export const extractEducation = (education: IMCandidate['education'], dictionary: Dictionary): CandidateEducation[] => {
  return education.map(({ name, startYear, endYear, fullDuration, description }) => {
    const currentOrEndYear = endYear ?? dictionary.current;

    const splittedEducation = name.split('@');

    return {
      field: splittedEducation[0] ?? '',
      school: splittedEducation[1] ?? '',
      date: `${startYear} - ${currentOrEndYear}`,
      description,
      fullDuration: fullDuration(dictionary),
    };
  });
};

import { Row } from 'components/ui/atoms';
import { useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { CandidateCVProps, RequestCvErrorType } from './CandidateCV.types';
import { CandidateCardCvIframe } from './CandidateCVIframe';
import { CandidateCVPdf } from './CandidateCVPdf';
import { useStyles } from './CandidateCVRender.styles';
import { CandidateCVUnavailable } from './CandidateCVUnavailable';

export const CandidateCVRender = ({ candidate, cv }: CandidateCVProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();

  const errorMessage = {
    [RequestCvErrorType.formatIssues]: dictionary.cvFormatIssues,
    [RequestCvErrorType.notAvailable]: dictionary.notAvailableAnymore,
  };

  return (
    <Row css={classes.root} center contained>
      <Row css={[classes.pdfWrapper, cv.isPdf && classes.pdfWrapperCvFile]} contained>
        {cv.data === null ? (
          <CandidateCVUnavailable message={cv.error ? errorMessage[cv.error] : dictionary.somethingWentWrong} />
        ) : cv.isPdf ? (
          <CandidateCVPdf cvFile={cv.data} />
        ) : (
          <CandidateCardCvIframe
            cvFileUrl={cv.data}
            shouldUseGoogleDocs={candidate.activeJobBoard !== CampaignJobboard.Werknl}
          />
        )}
      </Row>
    </Row>
  );
};

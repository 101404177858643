import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '0 24px',
      width: '100%',
      height: '100%',
      gap: 12,
    }),
  actionButtonsWrapper: ({ theme }) =>
    css({
      width: 'fit-content',
      border: `solid 1px ${theme.colors.neutral.variant[20]}`,
      borderRadius: 8,
      padding: 4,
      gap: 11,
      backgroundColor: theme.colors.neutral.variant[0],
    }),
  divider: ({ theme }) =>
    css({
      display: 'flex',
      width: 1,
      height: 24,
      backgroundColor: theme.colors.neutral.variant[30],
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);

import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';
import { CandidateCVIframeStylesProp } from './CandidateCVIframe.types';

const styles = {
  root: ({ props: { shouldUseGoogleDocs } }) => css`
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    margin-right: ${shouldUseGoogleDocs ? 0 : '-30px '};
    max-width: 800px;
  `,
  iframe: ({ props: { shouldUseGoogleDocs } }) => css`
    height: 100%;
    width: 100%;

    border: 0;
    border-right: ${shouldUseGoogleDocs ? 0 : 'solid 15px white'};
    padding-top: ${shouldUseGoogleDocs ? 0 : '15px'};
  `,
  hideOpenAnotherPageIframeButton: ({ props: { shouldUseGoogleDocs } }) => css`
    position: absolute;
    top: 12px;
    right: 12px;
    width: 40px;
    height: 40px;
    background: ${shouldUseGoogleDocs ? 'white' : 'transparent'};
  `,
  skeleton: css`
    z-index: 1;
  `,
} satisfies Styles<CandidateCVIframeStylesProp>;

export const useStyles = withStyles(styles);

import { Column, Row, Typography } from 'components/ui/atoms';
import { Check } from 'components/ui/atoms/icons';
import { Button, Modal } from 'components/ui/molecules';
import { useContext, useState } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import SearchCampaigns from '../SearchCampaigns';
import { useStyles } from './styles';
import { ApproveCandidateToAnotherSearchModalProps } from './types';

const ApproveCandidateToAnotherSearchModal = ({
  candidateName,
  visible = false,
  onRequestClose,
  onSend,
  onApproveIsLoading,
  isLoading,
}: ApproveCandidateToAnotherSearchModalProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const [campaignId, setCampaignId] = useState<number | undefined>();

  const handleChange = (campaignId: number) => {
    setCampaignId(campaignId);
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <Column css={classes.root}>
        <Typography variant="header.h3">{dictionary.shortlistCandidateNameToOtherSearch(candidateName)}</Typography>
        <Typography variant="body.short">{dictionary.shortlistCandidateToOtherSearchDescription}</Typography>
        <SearchCampaigns handleChange={handleChange} />
        <Row css={classes.actions} right>
          <Button onClick={onRequestClose} variant="subtle" label={dictionary.cancel} />
          <Button
            variant="highlight"
            icon={<Check size={16} />}
            tooltipLabel={dictionary.save}
            onClick={() => campaignId && onSend(campaignId)}
            isLoading={isLoading}
            loading={onApproveIsLoading}
            disabled={!campaignId}
          />
        </Row>
      </Column>
    </Modal>
  );
};

export default ApproveCandidateToAnotherSearchModal;
